<template>
  <div>
    <div class="topbar" :class="visibleSidebar ? 'fix-header-position' : ''">
      <BarsIcon @click="$emit('change-sidebar-visible')" />

      <div class="float-right items">
        <WalletIcon class="fix-position" />
        <img class="avatar" src="https://beta.ec-at.com/cdn/ecat_images/5253f5ec-58c0-48ca-b3e7-14990bdb9c94.jpeg"
             alt="Avatar" />
      </div>
    </div>
  </div>
</template>
<script>
import BarsIcon from '@/components/icon/BarsIcon.vue'
import WalletIcon from '@/components/icon/WalletIcon.vue'

export default {
  name: 'Topbar',
  components: { WalletIcon, BarsIcon },

  props: {
    visibleSidebar: Boolean
  }
}
</script>

<style>
.topbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 1.75rem;
  box-shadow: 1px 1px 4px rgba(31, 31, 31, 0.15);
  background-color: #fff;
  width: 100%;
  height: 72px;
  z-index: 1;
}

@media (min-width: 768px) {
  .fix-header-position {
    max-width: calc(100% - 248px);
  }
}

.fix-position {
  margin-top: 0.2rem;
}

.items {
  margin-top: -0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.avatar {
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
</style>