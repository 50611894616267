<template>
  <div>
    <div class="footer">
      <span>&copy; 2024 GameModeStudio</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  z-index: 0;
  color: #575757;
  padding: 32px;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgba(31, 31, 31, 0.15);
}
</style>