<script>
export default {}
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2752 10.4451C19.2754 8.61487 18.7548 6.8229 17.7752 5.2813C16.7955 3.7397 15.3978 2.51304 13.7473 1.74639C12.0969 0.979738 10.2629 0.705221 8.46239 0.955378C6.66187 1.20553 4.97024 1.96985 3.58766 3.1579C2.20508 4.34594 1.18945 5.908 0.660978 7.65913C0.132505 9.41026 0.113308 11.2772 0.605663 13.0389C1.09802 14.8007 2.08132 16.3836 3.43918 17.6002C4.79703 18.8168 6.47259 19.6163 8.26757 19.9039V13.2428H5.85966V10.4717H8.26757V8.34013C8.21569 7.84663 8.27223 7.34773 8.4332 6.87869C8.59416 6.40966 8.85561 5.98202 9.199 5.62607C9.5424 5.27012 9.95931 4.99456 10.4202 4.81896C10.8812 4.64335 11.3748 4.57198 11.8662 4.6099C12.5798 4.6194 13.2918 4.68176 13.9963 4.79643V7.1545H12.7924C12.5867 7.11675 12.375 7.12861 12.1747 7.18914C11.9744 7.24968 11.7913 7.35717 11.6402 7.50277C11.4892 7.64836 11.3746 7.82792 11.3058 8.02675C11.237 8.22557 11.2159 8.43801 11.2444 8.64659V10.4451H13.8905L13.4803 13.2161H11.2576V19.8773C13.4956 19.5219 15.534 18.3739 17.0063 16.6399C18.4785 14.9059 19.2878 12.6997 19.2885 10.4184"
      fill="#FBFBFB" />
  </svg>
</template>

<style scoped lang="scss">

</style>