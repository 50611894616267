<template>
  <div class="card">
    <img src="@/assets/images/cards/card-4.png" alt="Card" />
    <div class="text">
      ASCRIBE #001
    </div>
  </div>
</template>

<style scoped>
.card {
  position: relative;
  display: inline-block;
}

img {
  width: 174px;
  height: 260px;
  display: block;
  transition: opacity 0.3s ease;
}

.card:hover > img {
  filter: brightness(50%);
}

.card:hover > .overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover > .text {
  opacity: 1;
}
</style>