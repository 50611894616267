<template>
  <div class="card">
    <div class="header">
      <h4>{{  title }}</h4>
      <span class="count"><i v-if="icon" :class="`fa ${icon}`" />{{ value }}</span>
    </div>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      default: ""
    },

    value: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.card {
  background-color: #fff;
  width: 100%;
  height: calc(100% - 40px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.count {
  font-size: 28px;
  font-weight: 500;
}

.count i {
  color: #506fd9;
  margin-right: 6px;
}

h4 {
  color: #495766;
  margin: 0;
  font-family: 'Archivo', sans-serif;
}

.description {
  color: #495766;
  font-family: 'Archivo', sans-serif;
}
</style>