<template>
  <div class="card" :class="scrollingCard ? 'scrolling-card' : ''">
    <div class="header">
      <span class="title">
        <span :class="`font-weight-600 ${titleColor}`">{{  title }}</span> <span class="font-weight-600">{{  subTitle }}</span>
      </span>
    </div>

    <div class="line" />

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    title: {
      type: String,
      required: true
    },

    titleColor: {
      type: String,
      default: ""
    },

    subTitle: {
      type: String,
      default: ""
    },

    scrollingCard: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style scoped>
.card {
  width: 100%;
  max-width: 785px;
  height: auto;
  background: white;
  box-shadow: 0 3px 10px -3px rgba(16, 18, 21, 0.20);
}

.header {
  padding: 1rem;
}

.line {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #DEDEDE;
}

.content {
  padding: 1rem;
  color: #575757;
  font-size: 16px;
}
</style>