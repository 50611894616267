const dp3 = [
    [0,40],
    [1,39],
    [2,35],
    [3,33],
    [4,28],
    [5,28],
    [6,24],
    [7,20],
    [8,17],
    [9,16],
    [10,19],
    [11,16],
    [12,13],
    [13,18],
    [14,17],
    [15,18],
    [16,18],
    [17,19],
    [18,18],
    [19,17],
    [20,20],
    [21,18],
    [22,17],
    [23,17],
    [24,15],
    [25,15],
    [26,14],
    [27,15],
    [28,18],
    [29,19],
    [30,23],
    [31,27],
    [32,30],
    [33,28],
    [34,29],
    [35,29],
    [36,27],
    [37,24],
    [38,22],
    [39,26],
    [40,28],
    [41,27],
    [42,30],
    [43,26],
    [44,22],
    [45,19],
    [46,16],
    [47,17],
    [48,20],
    [49,16],
    [50,12],
    [51,10],
    [52,7],
    [53,11],
    [54,15],
    [55,20],
    [56,22],
    [57,19],
    [58,18],
    [59,20],
    [60,17],
    [61,19],
    [62,18],
    [63,14],
    [64,9],
    [65,10],
    [66,6],
    [67,10],
    [68,12],
    [69,13],
    [70,18],
    [71,22],
    [72,22],
    [73,26],
    [74,22],
    [75,18],
    [76,19],
    [77,19],
    [78,18],
    [79,23],
    [80,20],
    [81,25],
    [82,28],
    [83,29],
    [84,27],
    [85,25],
    [86,25],
    [87,24],
    [88,20],
    [89,18],
    [90,18],
    [91,18],
    [92,22],
    [93,21],
    [94,26],
    [95,29],
    [96,26],
    [97,28],
    [98,30],
    [99,28],
    [100,30],
    [101,27],
    [102,30],
    [103,26]
];

export { dp3 }