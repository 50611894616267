<template>
  <ul :class="subMenu ? 'submenu' : 'menu'">
    <li v-for="(item, index) in items" :key="index">
      <a v-if="item.subItems" @click="changeVisible($event)">{{ item.name }}</a>
      <RouterLink v-else :class="!item.subItems ? 'hidden' : ''" :active-class="item.to ? 'active' : ''" :to="{ name: item.to }"><i v-if="item.icon" :class="item.icon" />{{  item.name }}</RouterLink>

      <NavigationBar v-if="item.subItems" :items="item.subItems" :sub-menu="true" />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    subMenu: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    changeVisible(event) {
      const el = event.target.closest('li');
      const hasChildren = el.querySelector('ul') !== null;
      if (!hasChildren) {
        return
      }

      if (el.classList.contains("open")) {
        el.classList.remove("open");
      } else {
        el.classList.add("open");
      }
    }
  }

}
</script>

<style scoped>
.menu {
  height: 100%;
  position: relative;
  list-style: none;
  padding: 0;
}

.menu li::before {
  content: "";
  width: calc(100% + 6rem);
  height: 100%;
  position: absolute;
  left: -3rem;
  top: 0;
  background: white;
  border-top: 0.1rem rgba(31, 31, 31, 0.15) solid;
}

.menu li:last-child::after {
  content: "";
  width: calc(100% + 6rem);
  height: 100%;
  position: absolute;
  left: -3rem;
  top: calc(100% + 0.5rem);
  background: white;
  border-top: 0.1rem rgba(31, 31, 31, 0.15) solid;
}

.menu li ul.submenu > li::before {
  display: none;
}

.menu li {
  position: relative;
  padding: 0.5rem;
  margin: 8px 0;
  border-radius: 8px;
}

.menu .submenu li {
  padding: 0.35rem;
}

.menu .open > .submenu {
  padding-top: 1rem;
}

.menu a:hover {
  color: #B51919;
}

.active {
  color: #B51919 !important;
}

.menu a img {
  background-color: rgba(31, 31, 31, 0.75);
}

.menu a {
  color: rgba(31, 31, 31, 0.75);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 0.3rem;
  position: relative;
}

.menu a span {
  overflow: hidden;
}

.menu a i {
  font-size: 1.2rem;
}

.menu li ul.submenu {
  height: 0;
  overflow: hidden;
  padding-left: 20px;
}

.menu li.open > ul.submenu {
  height: auto;
}

.menu li > a::after {
  content: '\203A';
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: transform 0.3s ease-out;
}

.menu li > a.hidden::after {
  display: none;
}

.menu li.open > a::after {
  transform: translateY(-50%) rotate(90deg);
}

li {
  cursor: pointer;
}
</style>