import { createRouter, createWebHistory } from 'vue-router'
import LoginPageView from '@/views/panel/LoginPageView.vue'
import HomeView from '@/views/panel/HomeView.vue'
import Home from '@/views/HomeView.vue'
import ProfitCardsView from '@/views/panel/ascribe/ProfitCardsView.vue'
import StatisticsView from '@/views/panel/ascribe/StatisticsView.vue'
import RecruitmentView from '@/views/RecruitmentView.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPageView
    },
    {
      path: '/recruitment',
      name: 'recruitment',
      component: RecruitmentView
    },
    {
      path: '/dashboard/home',
      name: 'dashboard-home',
      component: HomeView
    },
    {
      path: '/dashboard/ascribe/profit-cards',
      name: 'dashboard-ascribe-profit-cards',
      component: ProfitCardsView
    },
    {
      path: '/dashboard/ascribe/statistics',
      name: 'dashboard-ascribe-statistics',
      component: StatisticsView
    }
  ]
})

export default router
