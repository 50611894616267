<script>
import SocialIcon from '@/components/SocialIcon.vue'
import { markRaw } from 'vue'
import TikTokIcon from '@/components/icon/TikTokIcon.vue'
import FacebookIcon from '@/components/icon/FacebookIcon.vue'
import InstagramIcon from '@/components/icon/InstagramIcon.vue'
import DiscordIcon from '@/components/icon/DiscordIcon.vue'

export default {
  components: { SocialIcon },

  data() {
    return {
      socialData: [
        {
          icon: markRaw(TikTokIcon),
          url: 'https://google.com'
        },
        {
          icon: markRaw(FacebookIcon),
          url: 'https://google.com'
        },
        {
          icon: markRaw(InstagramIcon),
          url: 'https://google.com'
        },
        {
          icon: markRaw(DiscordIcon),
          url: 'https://google.com'
        }
      ]
    }
  }
}
</script>

<template>
  <div>
    <div class="footer">
      <div class="box d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-start socials-container">
          Join our community:
          <div class="d-flex flex-row socials">
            <SocialIcon v-for="(social, index) in socialData" :key="index">
              <component :is="social.icon" />
            </SocialIcon>
          </div>
        </div>

        <div class="links d-flex flex-row">
          <div class="link">
            Terms of use
          </div>
          <div>
            &nbsp;|&nbsp;
          </div>
          <div class="link">
            Privacy Policy
          </div>
        </div>

        <div class="copyright d-flex flex-row align-items-center">
          Copyright © 2023 GameMode.Studio

          <img src="@/assets/images/logo-column.png" alt="GameMode.Studio" class="Logo GameModeStudio">
        </div>
      </div>


    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  position: relative;
  height: 120px;


  filter: drop-shadow(0 -5px 20px rgba(0, 0, 0, 0.25));


  &:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    height: 120px;
    width: 100%;

    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(227, 227, 227, 1) 100%);
    clip-path: polygon(3% 0, 97% 0, 100% 25%, 100% 100%, 0 100%, 0 25%);

    z-index: 1;
  }

  .box {
    position: relative;

    max-width: 1300px;
    margin: 0 auto;

    height: 100%;

    z-index: 2;

    .socials-container {
      font-family: "Montserrat Regular", sans-serif;
      font-size: 16px;

      color: #232323;

      line-height: 20px;

      gap: 27px;

      .socials {
        gap: 15px;
      }
    }

    .links {
      font-family: "Montserrat Medium", sans-serif;
      font-size: 16px;

      color: #1F1F1F;

      line-height: 20px;
    }

    .copyright {
      font-family: "Montserrat Regular", sans-serif;
      font-size: 14px;

      color: #1F1F1F;

      line-height: 170%;

      gap: 25px;
    }
  }
}
</style>