<script>
export default {
  name: 'GameCard',

  data() {
    return {
      hover: false,
    }
  },

  props: {
    image: {
      type: String,
      default: 'https://via.placeholder.com/300'
    }
  }
}
</script>

<template>
  <div>
    <div class="game-card" @mouseenter="hover = true" @mouseleave="hover = false" :class="{ hovered: hover }">
      <div class="image-container">
        <img :src="require(`@/assets/images/games/${image}`)" alt="">
      </div>

      <div class="content">
        <div class="title text-center">
          <slot name="title" />
        </div>
        <div class="description text-center">
          <slot name="description" />
        </div>
        <div class="footer w-75">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.game-card {
  position: relative;

  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: scale(1);

  &.hovered {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .content {
      opacity: 1;
    }
  }

  .image-container {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 0 18px;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);

    opacity: 0;
    transition: opacity 0.3s ease, background-color 0.3s ease;

    .title {
      font-family: "Montserrat Bold", sans-serif;
      font-size: 24px;

      color: #FBFBFB;

      line-height: 29px;

      padding-bottom: 25px;
    }

    .description {
      font-family: "Montserrat Regular", sans-serif;
      font-size: 16px;

      color: #FBFBFB;

      line-height: 20px;

      padding-bottom: 25px;
    }
  }
}
</style>