<script>
export default {
  name: 'AdvancedBox',

  props: {
    showLine: {
      type: Boolean,
      default: false
    },
    lineType:  {
      type: String,
      default: 'black' // 'black', 'red'
    },
    row: {
      type: Boolean,
      default: true
    }
  }

}
</script>

<template>
  <div>
    <div class="advanced-title d-flex justify-content-start align-items-center">
      <slot name="title" /> <div class="line flex-grow-1" :class="lineType" v-show="showLine"/>
    </div>
    <div class="advanced-content">
      <slot name="content" />
    </div>
    <div class="advanced-footer">
      <div  :class="row ? 'row' : ''">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.advanced-title {
  font-family: "Montserrat Bold", sans-serif;
  font-size: 48px;

  text-transform: uppercase;

  line-height: 59px;

  color: #1F1F1F;

  padding-bottom: 25px;

  span {
    font-family: "Montserrat Bold", sans-serif;
    font-size: 48px;

    text-transform: uppercase;

    line-height: 59px;

    color: #B51919;
  }

  gap: 10px;

  .line {
    &.black {
      border-top: 2px solid #1F1F1F;
    }

    &.red {
      border-top: 2px solid #B51919;
    }
  }
}

.advanced-content {
  font-family: "Montserrat Regular", sans-serif;
  font-size: 20px;

  color: #1F1F1F;

  line-height: 24px;

  padding-bottom: 30px;

  span {
    font-family: "Montserrat Bold", sans-serif;
    font-size: 20px;

    color: #B51919;

    line-height: 24px;
  }

  .description {
    font-family: "Montserrat Regular", sans-serif;
    font-size: 16px;

    color: #1F1F1F;

    line-height: 20px;
  }
}


</style>