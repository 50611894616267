<script>
export default {
  name: "Input",
  components: {},
  props: {
    type: {
      type: String,
      default: "text", // 'text', 'password', 'number', 'textarea'
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    regex: {
      type: RegExp,
      default: () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    },
    disableRegex: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: "",
    },
    searchInputType: {
      type: String,
      default: '', // 'small', 'large'
    },
    textAreaRows: {
      type: Number,
      default: 15,
    }
  },
  data() {
    return {
      inputValue: this.value,
      passwordVisible: false,
      state: "normal",
    };
  },
  computed: {
    isPassword() {
      return this.type === "password";
    },

    inputType() {
      return this.isPassword && !this.passwordVisible ? "password" : "text";
    },
  },
  methods: {
    validateInput() {
      if (this.inputValue && !this.regex.test(this.inputValue) && !this.disableRegex) {
        this.state = "error";
      } else if (this.inputValue) {
        this.state = "done";
      } else {
        this.state = "active";
      }
    },

    clearValidate() {
      this.state = "active";
    },
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
      this.validateInput();
    },
  },
};
</script>

<template>
  <div>
    <div class="input-group" :class="state">
      <div class="wrapper position-relative d-flex align-items-center">
        <input
          :type="inputType"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="inputValue"
          @click="clearValidate"
          @focusout="validateInput"
          class="field"
          :class="[inputClass, searchInputType !== '' ? 'search-input-' + searchInputType : '']"
          v-if="type !== 'textarea'"
        />

        <textarea
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="inputValue"
          @click="clearValidate"
          @focusout="validateInput"
          class="field"
          :class="[inputClass, searchInputType !== '' ? 'search-input-' + searchInputType : '']"
          :rows="textAreaRows"
          v-if="type === 'textarea'"
        />
      </div>
      <div v-if="state === 'error'" class="error-message pt-2 text-end">{{
          errorMessage ? errorMessage : "Error message"
        }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/custom";

.input-group {
  .label {
    font-family: "Montserrat Regular", sans-serif;
    font-size: 14px;

    text-transform: uppercase;

    color: rgba(167, 160, 179, 1);
  }

  &.error {
    .field {
      border-color: #FF6060;

      color: rgba(175, 178, 187, 1);

      background-color: rgba(17, 18, 21, 1);

      box-shadow: none;
    }
  }

  .field {
    font-family: "Montserrat Regular", sans-serif;
    font-size: 16px;

    width: 100%;
    padding: 14px 16px;

    line-height: 122%;

    border: 1px solid rgba(237, 237, 237, 0.2);

    background-color: #E9E9E9;

    color:  rgba(31, 31, 31, 0.5);

    transition: all 0.3s ease;

    &.search-input-small {
      padding-right: 136px;
    }

    &.search-input-large {
      padding-right: 289px;
    }

    &::placeholder {
      font-family: "Montserrat Regular", sans-serif;
      font-size: 16px;

      color: rgba(31, 31, 31, 0.5);
    }

    &:hover {
      //background-color: #16171A;
      //border-color: rgba(237, 237, 237, 0.3);
    }

    :disabled {
      font-family: "Montserrat Bold", sans-serif;

      color: rgba(179, 182, 195, 0.3);

      background-color: transparent;
      border-color: rgba(242, 242, 242, 0.2);

      &::placeholder {
        color: rgba(179, 182, 195, 0.3);
      }
    }

    &:focus {
      outline: none;

/*      border-color: #C55DFF;

      background-color: #111215;

      @include drop-shadow(0, 4px, 15px, -5px, rgba(197, 93, 255, 0.5));*/
    }
  }
}

.toggle-password {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.error-message {
  color: #FF6060;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}
</style>
