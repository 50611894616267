<template>
  <div class="card">
    <img src="@/assets/images/cards/card-4.png" alt="Card" />

    <div class="text-center align-bottom">
      <p class="title font-size-16">ASCRIBE #001</p>
      <p class="font-size-16">250 $</p>
    </div>

    <button>Buy Now ></button>
  </div>
</template>

<style scoped>
.card {
  position: relative;
  width: 254px;
  height: 430px;
  background: white;
  box-shadow: 0 3px 10px -3px rgba(16, 18, 21, 0.20);
  transition: box-shadow 0.3s ease;
  overflow: hidden;
}

.card:hover {
  box-shadow: 0 3px 10px 6px rgba(16, 18, 21, 0.20);
}

.card:hover p {
  color: #B51919;
}

img {
  width: 222px;
  height: 334px;
  margin: 1rem 0 0 1rem;
}

.align-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
}

.align-bottom p:last-child {
  margin-top: auto;
}

button {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 191px;
  height: 44px;
  background-color: #B51919;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card:hover button {
  opacity: 1;
}

img {
  width: 222px;
  height: 334px;
  margin: 1rem 0 0 1rem;
  transition: opacity 0.3s ease;
}

.card:hover img {
  filter: brightness(50%);
}
</style>