<script>
import Button from '@/components/Button.vue'

export default {
  name: 'RecruitmentCard',
  components: { Button },

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<template>
  <div>
    <div class="recruitment-card d-flex align-items-center justify-content-start flex-column">
      <div class="title text-center">
        {{ data.time }} |
        <span>{{ data.role }}</span>
      </div>
      <div class="description text-center" v-html="data.title"/>

      <div class="footer w-50">
        <Button
          :transform="false"
        >
          Apply
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.recruitment-card {
  position: relative;

  background: rgba(255, 255, 255, 0.75);

  backdrop-filter: blur(12px);
  box-shadow: 0 4px 21px 0 rgba(0, 0, 0, 0.25);

  .title {
    font-family: "Montserrat Regular", sans-serif;
    font-size: 16px;

    color: rgba(31, 31, 31, 0.5);

    line-height: 20px;

    padding-top: 25px;

    span {
      font-family: "Montserrat Regular", sans-serif;
      font-size: 16px;

      color: rgba(181, 25, 25, 0.5);

      line-height: 20px;
    }
  }

  .description {
    font-family: "Montserrat Bold", sans-serif;
    font-size: 32px;

    color: #1F1F1F;

    text-transform: uppercase;

    line-height: 32px;

    span {
      font-family: "Montserrat Bold", sans-serif;
      font-size: 32px;

      color: #B51919;

      text-transform: uppercase;

      line-height: 32px;
    }

    padding-top: 7px;
    padding-bottom: 45px;
  }

  .footer {
    position: absolute;
    bottom: -15px;
  }
}
</style>