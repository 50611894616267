<script>
export default {
  name: 'SocialIcon'
}
</script>

<template>
  <div>
    <div class="social-icon d-flex align-items-center justify-content-center">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.social-icon {
  width: 35px;
  height: 35px;

  background: #1F1F1F;
}
</style>