<script>
import Layout from '@/components/layout/default/Layout.vue'
import AdvancedBox from '@/components/AdvancedBox.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import InputFile from '@/components/InputFile.vue'

export default {
  components: { InputFile, Input, Button, AdvancedBox, Layout }

}
</script>

<template>
  <Layout>
    <div class="bg-variant-5">
      <div class="section h-100">
        <div class="row d-flex align-items-center justify-content-center vh-100">
          <div class="col-12">
            <div class="title text-center">
              VFX ARTIST
            </div>
            <div class="description text-center">
              Are you a veteran combat and gameplay designer? Do you have the chops to take it to the next level? Have a
              look at this exciting vacancy!
              <br><br>
              <span>Game Mode Studio</span> is looking for a Game Design Director. As the successful candidate, you will
              provide
              direction to the Combat, Robot and System Design teams and work with multi-disciplinary teams to create a
              game of the highest possible quality. This is an exceptional opportunity to join Guerrilla, liaison with
              the
              Game Director and oversee and direct all aspects related to combat and system design to make the highest
              quality player experience.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-variant-6">
      <div class="section">
        <div class="row">
          <div class="col-6">
            <AdvancedBox>
              <template #title>
                What will<span>you do</span>
              </template>
              <template #content>
                <div class="advanced-body-recruitment">
                  As a Game Design Director at <span class="advanced-data-bold">GMS</span>, we will look to you to:
                </div>
                <br>
                <ul class="custom-list">
                  <li>
                    Be instrumental in setting the direction and quality bars for combat gameplay and core design
                    features.
                  </li>
                  <li>
                    Collaborate with multidisciplinary teams to design, validate and amend high-level design concepts,
                    features and assets, efficiently and to quality.
                  </li>
                  <li>
                    Work with teams of skilled designers through all phases of development from concepting and
                    prototyping
                    to shipping by means of professional collaboration and communication.
                  </li>
                  <li>
                    Evaluate the game and playtest results, and present and discuss analyses with the Game Director, and
                    collaborate on plans to increase quality.
                  </li>
                  <li>
                    Collaborate with the data analytics and player research teams to further the understanding of the
                    player
                    experience and quality.
                  </li>
                  <li>
                    Guide, mentor and assist the leads of Combat, System and Robot Design.
                  </li>
                  <li>
                    Oversee strategic needs in terms of recruitment of top-class talent to strengthen the design teams.
                  </li>
                </ul>
              </template>
            </AdvancedBox>

            <AdvancedBox>
              <template #title>
                What will<span>you do</span>
              </template>
              <template #content>
                <div class="advanced-body-recruitment">
                  As a Game Design Director at <span class="advanced-data-bold">GMS</span>, we will look to you to:
                </div>
                <br>
                <ul class="custom-list">
                  <li>
                    Be instrumental in setting the direction and quality bars for combat gameplay and core design
                    features.
                  </li>
                  <li>
                    Collaborate with multidisciplinary teams to design, validate and amend high-level design concepts,
                    features and assets, efficiently and to quality.
                  </li>
                  <li>
                    Work with teams of skilled designers through all phases of development from concepting and
                    prototyping
                    to shipping by means of professional collaboration and communication.
                  </li>
                  <li>
                    Evaluate the game and playtest results, and present and discuss analyses with the Game Director, and
                    collaborate on plans to increase quality.
                  </li>
                  <li>
                    Collaborate with the data analytics and player research teams to further the understanding of the
                    player
                    experience and quality.
                  </li>
                  <li>
                    Guide, mentor and assist the leads of Combat, System and Robot Design.
                  </li>
                  <li>
                    Oversee strategic needs in terms of recruitment of top-class talent to strengthen the design teams.
                  </li>
                </ul>
              </template>
            </AdvancedBox>
          </div>

          <div class="col-6">
            <div>
              <div class="custom-card">
                <div class="title">
                  apply for this job
                </div>

                <div class="body">
                  <div class="row gx-4 gy-3">
                    <Input
                      placeholder="First name"
                      type="text"
                      disable-regex
                      class="col-6"
                    />

                    <Input
                      placeholder="Last name"
                      type="text"
                      disable-regex
                      class="col-6"
                    />

                    <Input
                      placeholder="Email"
                      type="text"
                      disable-regex
                      class="col-6"
                    />

                    <Input
                      placeholder="Phone number"
                      type="text"
                      disable-regex
                      class="col-6"
                    />

                    <Input
                      placeholder="Location (city)"
                      type="text"
                      disable-regex
                      class="col-12"
                    />

                    <InputFile
                      title="Cover Letter"
                      :allowed-files-types='["pdf", "doc", "docx", "txt", "rtf"]'
                      class="col-6"
                    />

                    <InputFile
                      title="Resume/CV"
                      :allowed-files-types='["pdf", "doc", "docx", "txt", "rtf"]'
                      class="col-6"
                    />

                    <Button
                      class="col-12"
                    >
                      SEND
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style scoped lang="scss">
.custom-card {
  padding: 20px;

  background: #FFFFFF;

  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.25);

  .title {
    font-family: "Montserrat Bold", sans-serif;
    font-size: 24px;

    text-transform: uppercase;

    color: #1F1F1F;

    line-height: 29px;

    padding-bottom: 45px;
  }
}


.advanced-body-recruitment {
  font-family: "Montserrat Regular", sans-serif;
  font-size: 20px;

  color: #1F1F1F;

  line-height: 24px;

  .advanced-data-bold {
    font-family: "Montserrat Bold", sans-serif;
    font-size: 20px;

    color: #1F1F1F;

    line-height: 24px;
  }
}

.custom-list {
  font-family: "Montserrat Regular", sans-serif;
  font-size: 16px;

  line-height: 20px;

  color: #1F1F1F;
}

.bg-variant-6 {
  .section {
    padding: 30px 0 70px 0;
  }
}

.bg-variant-5 {
  .section {
    min-height: 100vh;
    padding: 0 350px;
  }
}


.title {
  font-family: "Montserrat Bold", sans-serif;
  font-size: 48px;

  color: #FFFFFF;

  line-height: 59px;
}

.description {
  font-family: "Montserrat Regular", sans-serif;
  font-size: 16px;

  color: #ffffff;

  line-height: 20px;

  span {
    font-family: "Montserrat Bold", sans-serif;
    font-size: 16px;

    color: #ffffff;

    line-height: 20px;
  }
}
</style>