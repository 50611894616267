<template>
  <div class="wrapper">
    <SideBar @change-sidebar-visible="visibleSidebar = !visibleSidebar" :visible="visibleSidebar" />
    <div class="main">
      <TopBar :visible-sidebar="visibleSidebar" @change-sidebar-visible="visibleSidebar = !visibleSidebar" />
      <div class="content">
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/layout/panel/SideBar.vue";
import TopBar from "@/components/layout/panel/TopBar.vue";
import Footer from "@/components/layout/panel/Footer.vue";

export default {
  components: {
    Footer,
    SideBar,
    TopBar
  },

  data() {
    return {
      visibleSidebar: true
    }
  },

  created() {
    const width = window.innerWidth
    this.visibleSidebar = width >= 720;
  }

}
</script>

<style scoped>
.wrapper {
  display: flex;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.content {
  padding: 1.5rem;
  margin-top: 6rem;
}
</style>