<template>
  <Layout>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/panel/Layout.vue";

export default {
  components: { Layout },
}
</script>