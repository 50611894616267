<script>
import TopBar from '@/components/layout/default/TopBar.vue'
import Footer from '@/components/layout/default/Footer.vue'

export default {
  name: 'Layout',

  components: {
    TopBar,
    Footer
  }
}
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <div class="main-content d-flex flex-column">
        <TopBar />
        <div class="d-flex flex-column justify-content-center flex-grow-1" ref="main">
          <slot />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<style scoped>
.main-content {
  min-height: 100vh;
}
</style>
