<script setup>
</script>

<template>
  <router-view/>
</template>

<style>
.button-items {
  margin-left: -8px;
  margin-bottom: -12px;

  .btn {
    margin-bottom: 12px;
    margin-left: 8px;
  }
}
</style>