<script>
import Layout from '@/components/layout/default/Layout.vue'
import AdvancedBox from '@/components/AdvancedBox.vue'
import GameCard from '@/components/GameCard.vue'
import Button from '@/components/Button.vue'
import RecruitmentCard from '@/components/RecruitmentCard.vue'

export default {
  components: { RecruitmentCard, Button, GameCard, AdvancedBox, Layout },

  data() {
    return {
      games: [
        {
          title: 'Game 1',
          description: 'is a game that fits into the Battle Royale genre. In short - there are 100 people for one game, and there is only one winner. Each game begins on an island surrounded by a deadly fog that is constantly shrinking.',
          image: 'ravenheim.png',
          url: 'https://google.com'
        },
        {
          title: 'Game 2',
          description: 'is a game that fits into the Battle Royale genre. In short - there are 100 people for one game, and there is only one winner. Each game begins on an island surrounded by a deadly fog that is constantly shrinking.',
          image: 'dynamism.png',
          url: 'https://google.com'
        },
        {
          title: 'Game 3',
          description: 'is a game that fits into the Battle Royale genre. In short - there are 100 people for one game, and there is only one winner. Each game begins on an island surrounded by a deadly fog that is constantly shrinking.',
          image: 'ascribe.png',
          url: 'https://google.com'
        },
        {
          title: 'Game 4',
          description: 'is a game that fits into the Battle Royale genre. In short - there are 100 people for one game, and there is only one winner. Each game begins on an island surrounded by a deadly fog that is constantly shrinking.',
          image: 'ridersRoyale.png',
          url: 'https://google.com'
        }
      ],

      recruitment: [
        {
          role: 'Art',
          time: 'Full Time',
          title: 'VFX <span>Artist</span>',
          url: 'https://google.com'
        },
        {
          role: 'Animation',
          time: 'Full Time',
          title: 'SENIOR 3D <span>Animator</span>',
          url: 'https://google.com'
        }
      ]
    }
  }
}
</script>

<template>
  <Layout>
    <div class="bg-variant-1">
      <div class="section">
        <div class="row flex-container">
          <AdvancedBox
            show-line
            class="col-6 box-2"
            v-motion-slide-visible-once-left
            :duration="1000"
          >
            <template #title>
              OUR <span>PROJECTS</span>
            </template>
            <template #content>
              At <span>GameMode Studio</span>, we believe that games have the power to entertain, inspire, and connect
              people from all
              walks of life
              <br><br>
              <div class="description">What sets us apart is our commitment to originality and innovation. We constantly
                challenge ourselves to
                break new ground, explore uncharted territories, and bring fresh ideas to life. Our games are a
                reflection
                of our creative vision and our dedication to delivering high-quality, polished experiences that resonate
                with players worldwide.
              </div>
            </template>
            <template #footer>
              <Button class="col-5">INVESTORS</Button>
            </template>
          </AdvancedBox>
          <div class="col-6 box-1">
            <div class="row g-5">
              <GameCard
                v-for="(game, index) in games"
                :key="index"
                :image="game.image"
                class="col-6"
                v-motion-fade-visible-once
                :duration="1000"
              >
                <template #title>
                  {{ game.title }}
                </template>
                <template #description>
                  {{ game.description }}
                </template>
                <template #footer>
                  <Button
                    :transform="false"
                  >
                    Learn more
                  </Button>
                </template>


              </GameCard>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-variant-2">
      <div class="section">
        <div class="row">
          <div class="col-6">
            <img src="@/assets/images/render-2.png" alt="" class="about-image">
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">

            <AdvancedBox
              show-line
              :row="false"
              v-motion-slide-visible-once-right
              :duration="1000"
            >
              <template #title>
                <span>about</span>us
              </template>
              <template #content>
                At <span>GameMode Studio</span>, we believe that games have the power to entertain, inspire, and connect
                people from all
                walks of life
                <br><br>
                <div class="description">What sets us apart is our commitment to originality and innovation. We
                  constantly
                  challenge ourselves to
                  break new ground, explore uncharted territories, and bring fresh ideas to life. Our games are a
                  reflection
                  of our creative vision and our dedication to delivering high-quality, polished experiences that
                  resonate
                  with players worldwide.
                </div>
              </template>
              <template #footer>
                Get ready to embark on unforgettable gaming adventures with us.

                <div class="animation-box">
                  Let's play, create, and explore <span>together!</span>
                </div>
              </template>
            </AdvancedBox>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-variant-3">
      <div class="section">
        <div class="row">
          <div class="col-12 recruitment-title text-center">
            Join our team
          </div>
          <div class="col-12 recruitment-size">
            Current oppenings ({{ recruitment.length }}):
          </div>
          <RecruitmentCard
            v-for="(recruit, index) in recruitment"
            :key="index"
            :data="recruit"
            class="col-6"
            v-motion-fade-visible-once
            :duration="1000"
          />
        </div>
      </div>
    </div>

    <div class="bg-variant-4">
      <div class="section">
        <div class="row">
          <AdvancedBox
            show-line
            line-type="red"
            class="col-6"
            v-motion-slide-visible-once-left
            :duration="1000"
          >
            <template #title>
              for<span>investors</span>
            </template>
            <template #content>
              Nam lobortis eros vulputate justo condimentum gravida. Vestibulum ultrices orci a nibh accumsan aliquet.
              Suspendisse tincidunt tempus neque.
              <br><br>
              Pellentesque sed commodo risus, nec semper lacus. Nulla ut turpis rhoncus erat pulvinar venenatis. Nulla
              facilisi. Vivamus imperdiet condimentum dui ac eleifend.
            </template>
            <template #footer>
              <Button
                class="col-6"
              >
                INVESTORS
              </Button>
              <Button
                class="col-6"
                type="white"
                :transform="false"
              >
                Learn More
              </Button>
            </template>
          </AdvancedBox>
          <div class="col-6 d-flex align-items-center justify-content-center">
            <img src="@/assets/images/render-1.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.flex-container {
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-start !important;
}

.box-1 {
  position: sticky;
  top: 0;
}

.box-2 {
  position: sticky;
  bottom: 50%;
  align-self: flex-end;
}

.about-image {
  margin-left: -250px;
}


.recruitment-title {
  font-family: "Montserrat Bold", sans-serif;
  font-size: 48px;

  text-transform: uppercase;

  color: #FFFFFF;

  line-height: 58px;

  padding-bottom: 40px;
}

.recruitment-size {
  font-family: "Montserrat Regular", sans-serif;
  font-size: 16px;

  text-transform: uppercase;

  color: #FFFFFF;

  line-height: 20px;

  padding-bottom: 20px;
}

.animation-box {
  position: relative;

  font-family: "Montserrat SemiBold", sans-serif;
  font-size: 20px;

  height: 3em;

  color: #1F1F1F;

  line-height: 24px;

  padding-left: 0;

  span {
    font-family: "Montserrat SemiBold", sans-serif;
    font-size: 20px;

    color: #B51919;

    line-height: 24px;

    max-height: 1.2em;
  }

  overflow: hidden;

  &:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 65%;

    background-image: url("@/assets/images/line.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;


    animation: animation-a 5s linear infinite;
  }
}

@keyframes animation-a {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  100% {
    clip-path: inset(0 0 0 0);
  }
}
</style>