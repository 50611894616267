import '@/assets/css/main.css'
import '@/assets/scss/styles.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { MotionPlugin } from '@vueuse/motion'

import App from '@/App.vue'
import router from '@/router'
import VueApexCharts from 'vue3-apexcharts'

import NavigationBar from '@/components/NavigationBar.vue'

createApp(App)
  .use(createPinia())
  .use(router)
  .use(VueApexCharts)
  .use(MotionPlugin)

  .component('NavigationBar', NavigationBar)

  .mount('#app')
