const items = [
    {
        name: "HOME PAGE",
        to: 'dashboard-home'
    },
    {
        name: "ASCRIBE",
        subItems: [
            {
                name: "About Game"
            },
            {
                name: "Profit Cards",
                to: "dashboard-ascribe-profit-cards"
            },
            {
                name: "Statistics",
                to: "dashboard-ascribe-statistics"
            }
        ]
    },
    {
        name: "RAVENHEIM"
    },
    {
        name: "DYNAMISM"
    },
    {
        name: "RIDERS ROYALE"
    },
    {
        name: "THE RATING"
    }
]

export {items}