<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "red", // 'red', 'white'
    },
    transform: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<template>
  <div>
    <button class="custom-button w-100" :button-type="type" :class="transform ? 'text-uppercase' : ''">
      <slot/>
    </button>
  </div>
</template>

<style scoped lang="scss">
[button-type="red"] {
  background: linear-gradient(180deg, rgba(222, 7, 19, 1) 0%, rgba(181, 25, 25, 1) 100%);

  border: 1px solid transparent;

  color: #FFFFFF;
}

[button-type="white"] {
  background: transparent;
  border: 1px solid #1F1F1F;

  color: #1F1F1F;
}

.custom-button {
  font-family: "Montserrat Medium", sans-serif;
  font-size: 16px;

  line-height: 20px;

  padding: 10px 0;
}
</style>