<template>
  <div class="login-box">
    <img class="logo" src="@/assets/images/logo.png" alt="logo">
    <div class="login-container">
      <h2>Login</h2>
      <input type="email" placeholder="E-mail" ref="login">
      <input type="password" placeholder="Password" ref="password">
      <button @click="logIn" class="btn">Sign In</button>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";

export default {

  data() {
    return {
        login: 'admin',
        password: 'dazvan-cEgze8-tuczuf'
    }
  },

  methods: {
    logIn() {

      if (this.$refs.login.value === this.login && this.$refs.password.value === this.password) {
        router.push({ name: "dashboard-home"})
      } else {
        alert('Invalid login or password')
        return;
      }


      router.push({ name: "dashboard-home"})
    }
  }

}
</script>

<style scoped>
.login-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.logo {
  margin-bottom: 20px;
}

.login-container {
  width: 300px;
  height: 260px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 32px;
}

input {
  width: calc(100% - 40px);
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.btn {
  width: calc(100% - 40px);
  padding: 10px;
  margin-left: 20px;
  border: none;
  border-radius: 4px;
  background-color: #B51919;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}

.btn:hover {
  background-color: #c50000;
}
</style>