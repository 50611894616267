<template>
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_218_645)">
      <path d="M25 12.9579H23.5273V9.62071C23.5266 9.12898 23.3348 8.65762 22.9942 8.30991C22.6536 7.96221 22.1918 7.76651 21.7101 7.76572H19.7514L18.9091 7.54023L5.23975 3.80318C5.10174 3.76367 4.95734 3.75289 4.81516 3.77148C4.67298 3.79008 4.53592 3.83766 4.41211 3.91142C4.28777 3.98329 4.17885 4.07994 4.09182 4.19563C4.00479 4.31132 3.94144 4.44369 3.90552 4.58487L3.07198 7.77474H1.81727C1.33554 7.77553 0.873759 7.97123 0.533123 8.31893C0.192487 8.66664 0.000778872 9.138 0 9.62973V24.154C0.000778872 24.6457 0.192487 25.1171 0.533123 25.4648C0.873759 25.8125 1.33554 26.0082 1.81727 26.009H21.7189C22.2006 26.0082 22.6624 25.8125 23.003 25.4648C23.3437 25.1171 23.5354 24.6457 23.5362 24.154V20.8168H25.0088L25 12.9579ZM5.02474 4.98773L15.1891 7.76572H4.29135L5.02474 4.98773ZM22.0694 24.145C22.0694 24.2383 22.0331 24.3278 21.9685 24.3937C21.9038 24.4597 21.8162 24.4968 21.7248 24.4968H1.82316C1.73177 24.4968 1.64411 24.4597 1.57949 24.3937C1.51486 24.3278 1.47855 24.2383 1.47855 24.145V9.62071C1.47855 9.52742 1.51486 9.43795 1.57949 9.37198C1.64411 9.30602 1.73177 9.26896 1.82316 9.26896H21.7248C21.8162 9.26896 21.9038 9.30602 21.9685 9.37198C22.0331 9.43795 22.0694 9.52742 22.0694 9.62071V12.9579H17.3834C16.3639 12.9587 15.3864 13.3722 14.6653 14.1078C13.9442 14.8433 13.5383 15.8408 13.5368 16.8814C13.5383 17.9222 13.9441 18.92 14.6651 19.656C15.3862 20.392 16.3637 20.8062 17.3834 20.8078H22.0694V24.145ZM23.8366 19.6052H17.3922C16.6847 19.6044 16.0064 19.3172 15.5062 18.8065C15.0059 18.2959 14.7245 17.6035 14.7237 16.8814C14.7253 16.1597 15.007 15.4681 15.5072 14.9581C16.0074 14.4481 16.6852 14.1613 17.3922 14.1605H23.8366V19.6052Z" fill="#575757"/>
      <path d="M6.65353 11.4697H3.56976V12.3716H6.65353V11.4697Z" fill="#575757"/>
      <path d="M8.92438 13.9771H3.56976V14.879H8.92438V13.9771Z" fill="#575757"/>
      <path d="M15.6279 16.8904C15.6287 17.3733 15.817 17.8363 16.1516 18.1778C16.4861 18.5193 16.9397 18.7115 17.4128 18.7123C17.8859 18.7115 18.3395 18.5193 18.674 18.1778C19.0086 17.8363 19.1969 17.3733 19.1977 16.8904C19.1969 16.4074 19.0086 15.9445 18.674 15.603C18.3395 15.2614 17.8859 15.0692 17.4128 15.0685C16.9397 15.0692 16.4861 15.2614 16.1516 15.603C15.817 15.9445 15.6287 16.4074 15.6279 16.8904ZM17.4128 16.271C17.5735 16.2718 17.7274 16.3373 17.841 16.4533C17.9546 16.5693 18.0188 16.7264 18.0195 16.8904C18.0195 17.0546 17.9556 17.2122 17.8418 17.3283C17.728 17.4445 17.5737 17.5097 17.4128 17.5097C17.2519 17.5097 17.0976 17.4445 16.9838 17.3283C16.87 17.2122 16.8061 17.0546 16.8061 16.8904C16.806 16.7292 16.8675 16.5743 16.9775 16.4587C17.0875 16.343 17.2373 16.2757 17.3951 16.271H17.4128Z" fill="#575757"/>
      <path d="M6.3089 3.12974C6.44407 3.20933 6.60467 3.23092 6.75542 3.18977C6.90617 3.14863 7.03477 3.0481 7.11298 2.91027L8.05843 1.24167L15.5543 5.67021C15.6434 5.72416 15.7453 5.75223 15.8488 5.75138C15.9526 5.75181 16.0547 5.72422 16.1447 5.67142C16.2347 5.61863 16.3094 5.5425 16.3613 5.45074C16.3998 5.38224 16.4247 5.30667 16.4346 5.22835C16.4445 5.15003 16.4391 5.07049 16.4189 4.99427C16.3987 4.91806 16.3639 4.84666 16.3166 4.78415C16.2694 4.72164 16.2105 4.66925 16.1434 4.62997L8.55914 0.15032C8.31048 0.00356298 8.01492 -0.0364449 7.73739 0.0390819C7.46148 0.117577 7.22737 0.304644 7.08647 0.559201L6.09684 2.31198C6.01955 2.44932 5.99846 2.61224 6.03817 2.76536C6.07787 2.91848 6.17516 3.04942 6.3089 3.12974Z" fill="#575757"/>
    </g>
    <defs>
      <clipPath id="clip0_218_645">
        <rect width="25" height="26" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
svg {
  cursor: pointer;
}
</style>