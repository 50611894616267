<template>
  <div class="sidebar" :class="visible ? 'show' : ''">
    <div class="logo-container">
      <img class="logo" src="@/assets/images/logo.png" alt="logo">
    </div>

    <NavigationBar :items="items()" />

    <BarsIcon @click="$emit('change-sidebar-visible')" class="bars-icon" />
  </div>
</template>

<script>
import BarsIcon from '@/components/icon/BarsIcon.vue'
import { items } from '@/util/sidebar'

export default {
  name: "SideBar",
  components: { BarsIcon },

  props: {
    visible: Boolean
  },

  mounted() {
    this.updateSidebarLinks()
  },

  methods: {
    items() {
      return items
    },

    updateSidebarLinks() {
      const activeRouterLink = document.getElementsByClassName("active router-link-exact-active");

      if (activeRouterLink) {
        for (const link of activeRouterLink) {
          let parent = link.parentElement.parentElement.parentElement;
          while (parent && parent.tagName === "LI") {
            const a = parent.querySelector("a");
            if (a) {
              parent.classList.add("open")
              a.classList.add("active");
            }

            parent = parent.parentElement.parentElement;
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.sidebar {
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 248px;
  height: 100vh;
  padding: 0 1.5rem;
  color: #fff;
  overflow: hidden;
  background: rgba(255, 255, 255, 255);
  display: none;
}

.bars-icon {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    width: 100%;
  }

  .logo-container {
    text-align: center;
  }

  .logo {
    display: inline-block;
    vertical-align: middle;
  }

  .bars-icon {
    display: block;
  }
}

.show {
  display: block;
}

.logo-container {
  height: 64px;
  padding-top: 16px;
}
</style>