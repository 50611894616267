<script>
export default {}
</script>

<template>
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3013 1.62102C15.0504 1.0715 13.7299 0.681645 12.3735 0.461426C12.1879 0.77983 12.0199 1.10743 11.8703 1.44284C10.4255 1.23392 8.95614 1.23392 7.51128 1.44284C7.36162 1.10746 7.19367 0.779869 7.00814 0.461426C5.65083 0.683505 4.32946 1.07428 3.07735 1.62389C0.5916 5.15297 -0.0822487 8.59439 0.254676 11.987C1.71039 13.019 3.33976 13.804 5.07194 14.3076C5.46198 13.8042 5.80711 13.2702 6.10368 12.7112C5.54039 12.5093 4.99672 12.2602 4.47896 11.9668C4.61522 11.872 4.7485 11.7743 4.87728 11.6795C6.38385 12.3593 8.02819 12.7118 9.69305 12.7118C11.3579 12.7118 13.0022 12.3593 14.5088 11.6795C14.6391 11.7815 14.7724 11.8792 14.9071 11.9668C14.3884 12.2607 13.8437 12.5102 13.2794 12.7126C13.5756 13.2714 13.9208 13.8049 14.3112 14.3076C16.0448 13.806 17.6754 13.0214 19.1314 11.9884C19.5267 8.05411 18.4561 4.6443 16.3013 1.62102ZM6.53045 9.90055C5.59156 9.90055 4.81588 9.08294 4.81588 8.0771C4.81588 7.07126 5.5646 6.24646 6.52746 6.24646C7.49031 6.24646 8.26 7.07126 8.24352 8.0771C8.22705 9.08294 7.48732 9.90055 6.53045 9.90055ZM12.8556 9.90055C11.9153 9.90055 11.1426 9.08294 11.1426 8.0771C11.1426 7.07126 11.8913 6.24646 12.8556 6.24646C13.82 6.24646 14.5837 7.07126 14.5672 8.0771C14.5507 9.08294 13.8125 9.90055 12.8556 9.90055Z"
      fill="#FBFBFB" />
  </svg>
</template>

<style scoped lang="scss">

</style>