<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-12">
        <div class="float-left">
          <select v-model="selectedYear" class="custom-select">
            <option class="custom-select-option" value="" disabled>Select a year</option>
            <option class="custom-select-option" v-for="(year, index) in years" :value="year" :key="index">{{ year }}</option>
          </select>
        </div>

        <div class="float-right">
          <div class="button-items">
            <button class="btn btn-secondary"><i class="fa fa-print" /> Print</button>
            <button class="btn btn-primary"><i class="fa fa-bar-chart" /> Generate Report</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="chart-container">
          <div class="finance-info p-3 p-xl-4">
            <label class="fs-sm fw-medium mb-2 mb-xl-1">
              Profit This Year
            </label>
            <h1 class="finance-value">
              183,251.86 <span>PLN</span>
            </h1>

            <h4 class="finance-subvalue mb-3 mb-md-2">
              <i class="ri ri-arrow-up-line text-primary"></i>
              <span class="text-primary">175.21%</span>
              <small>vs last year</small>
            </h4>

            <div class="row row-cols-auto g-3 g-xl-4 pt-5">
              <div class="col statistics-col" v-for="(item, index) in quarters" :key="index">
                <h6 class="card-value fs-15 mb-1">{{ item.amount }} PLN</h6>
                <span class="fs-sm fw-medium text-secondary d-block mb-1">{{  item.quarter }} Quarter</span>
                <span class="fs-xs d-flex align-items-center ff-numerals" :class="`text-${item.status}`">{{ item.percent}}% <i class="ri fs-15 lh-3" :class="item.status === 'success' ? 'ri-arrow-up-line' : 'ri-arrow-down-line'" />
                </span>
              </div>
            </div>
          </div>

          <apexchart
              :series="seriesOne"
              :options="optionOne"
              type="area"
              :height="400"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-xl-3 col-lg-4 col-md-6">
        <CardInfo title="Paying Users" icon="fa-user" value="8,327" description="Total number of unique users who purchased at least one item during the selectyed time period" />
      </div>

      <div class="col-12 col-xl-3 col-lg-4 col-md-6">
        <CardInfo title="Purchases Value" icon="fa-shopping-cart" value="12,105 PLN" description="Total value of all the purchases made during the selected time period" />
      </div>

      <div class="col-12 col-xl-3 col-lg-4 col-md-6">
        <CardInfo title="Purchases Quantity" icon="fa-arrow-up" value="12,598" description="Total number of purchases made by the users during the selected time period" />
      </div>

      <div class="col-12 col-xl-3 col-lg-4 col-md-6">
        <CardInfo title="Conversion Rate" icon="fa-table" value="6.28%" description="Percentage of all our users who bought at least one item during the selected time period" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/panel/Layout.vue";
import CardInfo from "@/components/CardInfo.vue";
import {dp3} from "@/data/DashboardData.js";

export default {
  components: { Layout, CardInfo },
  data() {
    return {
      selectedYear: "2024",
      years: [],

      quarters: [
        {
          amount: "30,342.15",
          quarter: "First",
          percent: "2.3",
          status: "success",
        },
        {
          amount: "48,036.90",
          quarter: "Second",
          percent: "6.8",
          status: "success",
        },
        {
          amount: "68,156.00",
          quarter: "Third",
          percent: "10.5",
          status: "success",
        },
        {
          amount: "64,896.65",
          quarter: "Fourth",
          percent: "0.8",
          status: "danger",
        },
      ],

      seriesOne: [
        {
          name: "series1",
          data: dp3,
        },
        {
          name: "series2",
          data: dp3,
        },
      ],

      optionOne: {
        chart: {
          parentHeightOffset: 0,
          type: "area",
          toolbar: { show: false },
          stacked: true,
        },
        colors: ["#4f6fd9", "#506fd9"],
        grid: {
          borderColor: "rgba(72,94,144, 0.08)",
          padding: { top: -20 },
          yaxis: {
            lines: { show: false },
          },
        },
        stroke: {
          curve: "straight",
          width: [2, 0],
        },
        xaxis: {
          type: "numeric",
          tickAmount: 13,
          axisBorder: { show: false },
          labels: {
            style: {
              colors: "#6e7985",
              fontSize: "11px",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          show: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        tooltip: { enabled: false },
      }
    };
  },

  mounted() {
    this.generateYears();
  },

  methods: {
    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let year = 2024; year <= currentYear; year++) {
        this.years.push(year.toString());
      }
    }
  }

}
</script>

<style scoped>
.chart-container {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>