<script>
export default {

  props: {
    allowedFilesTypes: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      fileName: "",
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        if (!this.allowedFilesTypes.includes(file.name.split('.').pop())) {
          alert('Invalid file type');
          return;
        }

        this.fileName = file.name;
      }
    },
  },
};
</script>

<template>
  <div class="file-upload">
    <label class="label">
      {{ title }} <span class="formats">({{allowedFilesTypes.join(', ')}})</span>
      <button class="button" @click="triggerFileInput">Choose File</button>
      <span class="name">{{ fileName }}</span>
      <input
        type="file"
        class="file-input"
        @change="handleFileUpload"
        ref="fileInput"
      />
    </label>
  </div>
</template>

<style scoped lang="scss">
.file-upload {
  display: flex;
  flex-direction: column;

  .label {
    font-family: "Montserrat Regular", sans-serif;
    font-size: 14px;

    line-height: 17px;

    color: rgba(31, 31, 31, 1);

    .button {
      display: inline-block;

      margin-top: 5px;

      padding: 5px;

      background: #E6E6E6;

      font-family: "Montserrat Regular", sans-serif;
      font-size: 14px;

      color: #1F1F1F;

      border-radius: 2px;

      border: 1px solid #9B9B9B;
    }

    .formats {
      font-family: "Montserrat Regular", sans-serif;
      font-size: 14px;

      line-height: 17px;

      color: rgba(31, 31, 31, 0.5);
    }

    .file-input {
      display: none;
    }

    .name {
      display: inline-block;
      margin-left: 10px;
      font-style: italic;
      color: #333;
    }
  }

}
</style>
