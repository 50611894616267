<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="1.25" x2="16" y2="1.25" stroke="#1F1F1F" stroke-width="1.5"/>
    <line y1="15.25" x2="16" y2="15.25" stroke="#1F1F1F" stroke-width="1.5"/>
    <line y1="8.25" x2="10" y2="8.25" stroke="#1F1F1F" stroke-width="1.5"/>
  </svg>
</template>

<style scoped>
svg {
  cursor: pointer;
}
</style>