<script>
export default {}
</script>

<template>
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1068 4.94349C13.9858 4.87663 13.8684 4.80272 13.7551 4.72211C13.4217 4.48938 13.1154 4.21559 12.8427 3.9065C12.3172 3.27265 11.9639 2.49947 11.8204 1.66943C11.7776 1.40337 11.7776 1.13154 11.8204 0.865479H8.7316V13.519C8.7316 13.6937 8.7316 13.8569 8.7316 14.0316V14.1132C8.6889 14.6476 8.50032 15.1572 8.18889 15.5799C7.87746 16.0025 7.45664 16.3199 6.97785 16.4933C6.49905 16.6666 5.98299 16.6885 5.49268 16.5561C5.00238 16.4238 4.55905 16.1429 4.21683 15.7478C3.87461 15.3528 3.64832 14.8607 3.56558 14.3317C3.48285 13.8026 3.54725 13.2594 3.75093 12.7685C3.9546 12.2776 4.28874 11.8602 4.71254 11.5672C5.13633 11.2742 5.63144 11.1184 6.1374 11.1188C6.4102 11.1198 6.68109 11.167 6.93984 11.2586V7.92627C5.79914 7.75856 4.63737 7.96579 3.61129 8.52001C2.58522 9.07423 1.74423 9.94873 1.20184 11.0255C0.765659 11.8649 0.531574 12.8054 0.520316 13.7636C0.508976 14.5047 0.6206 15.2423 0.85009 15.9425C1.0542 16.5218 1.33553 17.0672 1.6855 17.562C2.06905 18.0717 2.52086 18.5192 3.02657 18.8903C3.96254 19.5467 5.05905 19.8991 6.18136 19.9039C6.99006 19.9036 7.78901 19.7168 8.52274 19.3564C9.24735 18.994 9.89448 18.4785 10.4244 17.8416C10.8635 17.2978 11.2128 16.6794 11.4577 16.0124C11.6817 15.3414 11.8077 14.6381 11.8314 13.9267V7.21547L12.3701 7.58833C12.9427 7.9477 13.5608 8.21842 14.2058 8.39228C14.8264 8.54794 15.4594 8.64155 16.0965 8.67191V5.42117C15.4053 5.44898 14.7193 5.2843 14.1068 4.94349Z"
      fill="#FBFBFB" />
  </svg>
</template>

<style scoped lang="scss">

</style>