<script>
import Button from '@/components/Button.vue'

export default {
  components: { Button },

  data() {
    return {
      navigationData: [
        {
          title: 'Our projects',
          link: '/projects'
        },
        {
          title: 'about us',
          link: '/about'
        },
        {
          title: 'careers',
          link: '/careers'
        },
        {
          title: 'investors',
          link: '/investors'
        }
      ]
    }
  }

}
</script>

<template>
  <div>
    <div class="header position-fixed">
      <div class="box">
        <div class="logo-container d-flex align-items-center justify-content-center">
          <img src="@/assets/images/logo-column.png" alt="Logo GameModeStudio">
        </div>


        <div class="items d-flex align-items-center justify-content-end">
          <div class="item cursor-pointer" v-for="(navigation, index) in navigationData" :key="index">
            {{ navigation.title }}
          </div>

          <Button
            class="w-15"
          >
            Login
          </Button>
        </div>


      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.header {
  width: 100%;

  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(227, 227, 227, 1) 100%);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.25);

  z-index: 25;

  .box {
    position: relative;

    max-width: 1300px;
    width: 100%;
    margin: 0 auto;

    .logo-container {
      position: absolute;
      left: 0;
      top: 0;

      height: 100px;
      width: 258px;

      filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.5));

      &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        height: 100px;
        width: 258px;

        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(227, 227, 227, 1) 100%);
        clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);

        z-index: 1;
      }

      img {
        z-index: 2;
        padding-top: 12px;
      }
    }

    .items {
      padding: 20px 0;

      .w-15 {
        width: 15% !important;
      }

      gap: 90px;

      .item {
        font-family: "Montserrat Medium", sans-serif;
        font-size: 16px;

        text-transform: uppercase;

        color: #1F1F1F;

        line-height: 20px;
      }
    }
  }
}


</style>